.ham_suboptions {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: white;
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ham_suboption {
    padding: 10px 30px;
    font-size: 18px;
    font-weight: normal;
    color: var(--color-primary);
    white-space: nowrap;
    border-top: 1px solid var(--ham-color-hover);
}

.ham_suboption:hover {
    background-color: var(--ham-color-hover);
}

.ham_container {
    --ham-size: 20px;
    --ham-pad: 10px;
    --ham-color-hover: var(--color-lighter-gray);
    position: relative;
    z-index: 100;
    width: calc(var(--ham-pad) * 2 + var(--ham-size));
    min-height: calc(var(--ham-pad) * 2 + var(--ham-size));
    /* height: 200px; */
}

.ham_menu {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    width: var(--ham-size);
    min-height: var(--ham-size);
    /* height: 200px; */
    padding: var(--ham-pad);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.ham_menu:hover {
    background-color: var(--ham-color-hover);
}

.ham_dot {
    --size: calc(var(--ham-size) / (var(--num-dots) * 2 - 2));
    background-color: var(--color-primary);
    border-radius: 50%;
    height: var(--size);
    width: var(--size);
    top: 20px;
    left: 20px;
}

.ham_options {
    cursor: pointer;
    position: absolute;
    background-color: white;
    left: calc(50% + 10px);
    top: calc(50%);
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.2);
}

.ham_option {
    padding: 10px 30px;
    font-size: 18px;
    font-weight: normal;
    color: var(--color-primary);
    white-space: nowrap;
    border-top: 1px solid var(--ham-color-hover);
}

.ham_option:hover {
    background-color: var(--ham-color-hover);
    /* color: white; */
}

.ham_option_disabled {
    color: var(--color-light-gray);
}

.ham_option_not_hoverable {
    background-color: white !important;
    cursor: default !important;
}
