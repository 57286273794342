.docProcess-container {
    position: relative;
}

.docProcess-header {
    margin: 0px 60px 20px 60px;
    padding-top: 20px;
}

.docProcess_headerText {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-primary);
    white-space: nowrap;
    /* margin-bottom: 10px; */
}

.docProcess_documentViewer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-lighter-gray);
    z-index: 1;
}

.docProcess-gearIcon {
    margin-right: 10px;
}

.docProcess-gearIcon.true path {
    fill: var(--color-dark-green);
}

.docProcess-gearIcon.false path {
    fill: var(--color-dark-gray);
}
