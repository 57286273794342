@mixin selected-style {
    border: 1px solid var(--color-link);
    background-color: var(--color-link);
    color: white;

    // &:hover {
    //     background-color: var(--color-link);
    // }
}

.hoverable.stat {
    &:hover {
        background-color: var(--color-link);
        cursor: pointer;
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .controlBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .controls {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 100%;
        gap: 10px;

        .hiddenLabel {
            font-size: 18px;
            font-weight: 600;
        }

        .metricSelection {
            display: flex;
            flex-wrap: wrap;
            // justify-content: right;
            // width: 100%;
            gap: 10px;

            .metricButton {
                user-select: none;
                font-size: 14px;
                border: 1px solid var(--color-lighter-gray);
                white-space: nowrap;
                align-items: center;
                width: fit-content;
                height: fit-content;
                padding: 5px 15px;
                border-radius: 20px;
                cursor: pointer;

                &:hover {
                    border: 1px solid var(--color-primary);
                    background-color: var(--color-lighter-gray);
                }
            }

            .selected {
                @include selected-style;
            }
        }
    }

    .statList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .stat {
            padding: 10px;
            border-radius: 4px;
            box-shadow: var(--dark-box-shadow);
            border: 1px solid var(--color-lighter-gray);
            min-width: 160px;

            .title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .statMetric {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            // &:hover {
            //     background-color: var(--color-lighter-gray);
            //     border: 1px solid var(--color-primary);
            // }
        }

        .selected {
            @include selected-style;
        }
    }
}
