.ToolbarContainer {
    background-color: rgba(40, 40, 40, 0.8);
    border-radius: 6px;
    bottom: 33px;
    cursor: pointer;
    height: 48px;
    left: 50%;
    position: sticky;
    transform: translate(-50%);
    transition: all 0.3s ease;
}

.toolbarTool {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    float: left;
    height: 48px;
    opacity: 0.7;
    width: 48px;
}

.toolbarTool:hover {
    background-color: hsla(0, 0%, 100%, 0.15);
    opacity: 1;
}

.toolbarToolSeparator {
    background-color: hsla(0, 0%, 100%, 0.1);
    display: block;
    float: left;
    height: 24px;
    margin-top: 12px;
    width: 1px;
}

.tool-infoText {
    color: hsla(0, 0%, 100%, 0.9);
    cursor: default;
    float: left;
    height: 48px;
    line-height: 48px;
    opacity: 0.7;
    text-align: center;
    width: 96px;
}

.toolbarTool-disabled {
    cursor: default;
    background-color: #bbb;
    opacity: 1;
}

.toolbarTool-disabled:hover {
    cursor: default;
    background-color: #bbb;
    opacity: 1;
}

.tool-zoom-in {
    background-image: url(https://i.ibb.co/2S9G6gr/zoom-in.png);
}

.tool-zoom-out {
    background-image: url(https://i.ibb.co/9WnVMV4/zoom-out.png);
}

.tool-fitWidth {
    background-image: url(https://i.ibb.co/cyR33Mj/fit-width.png);
}

.tool-fitScreen {
    background-image: url(https://i.ibb.co/Xsvwk8X/fit-screen.png);
}

.tool-previous {
    background-image: url(https://i.ibb.co/NjBbR1S/back-white.png);
}

.tool-next {
    background-image: url(https://i.ibb.co/Jp4FY1B/next-white.png);
}

.tool-search {
    background-image: url(https://i.ibb.co/HXk3nB6/search.png);
    height: 48px;
    width: 48px;
}

.tool-download {
    background-image: url(https://i.ibb.co/cNjmpjb/download-white.png);
    height: 48px;
    width: 48px;
}

.tool-close {
    background-image: url(https://i.ibb.co/yFswynn/imageedit-3-3061379625.png);
    filter: brightness(200%);
    height: 48px;
    width: 48px;
}

.tool-searchBar {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    float: left;
    height: 46px;
    width: 192px;
}

.tool-pageNumber {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    float: left;
    height: 46px;
    align-items: center;
    justify-content: center;
    width: 85px;
    color: hsla(0, 0%, 100%, 0.8);
    display: flex;
    flex-direction: column;
}

#searchBar {
    background-color: transparent;
    border: none;
    border-radius: 6px;
    color: hsla(0, 0%, 100%, 0.8);
    font-size: 16px;
    /* height: 46px; */
    line-height: 26px;
    margin: none;
    outline: none;
    padding: none;
    position: relative;
    text-align: center;
    width: 188px;
}

#searchBar:focus {
    background: linear-gradient(270deg, #00c1ef, #00c1ef);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 100% 3px;
}
